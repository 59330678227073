.wow-devices-table .btn:focus {
  box-shadow: none;
}

.wow-devices-th {
  vertical-align: top;
}

.wow-devices-th-sortable {
  cursor: pointer;
  user-select: none;
}

.wow-devices-th-index {
  min-width: 35px;
  width: 35px;
}

.wow-devices-th-model {
  min-width: 270px;
  width: 270px;
}

.wow-devices-th-publisher {
  min-width: 140px;
  width: 140px;
}

.wow-devices-th-actions {
  min-width: 247px;
  width: 247px;
}

.wow-devices-item-selected td {
  background-color: #d1e7dd;
}

.wow-devices-item-model {
  display: flex;
  justify-content: space-between;
}

.wow-devices-item-model span {
  display: inline-block;
}

.wow-devices-item-model-text {
  flex: 1;
}

.wow-devices-item-model-text span {
  display: block;
}

.wow-devices-item-model-text span:last-child {
  font-size: 0.6em;
  opacity: 0.7;
}

.wow-devices-item-btn {
  line-height: 1em;
  padding: 0.25rem;
}

.wow-devices-status-wr {
  position: relative;
}

.wow-devices-status-input {
  background: transparent;
  border: none;
  line-height: 20px;
  min-width: 210px;
  padding: 2px 50px 0 0;
  resize: none;
  width: 100%;
  -webkit-appearance: none;
}

.wow-devices-status-input:disabled {
  color: #212529;
  opacity: 1;
  -webkit-text-fill-color: #212529;
}

.wow-devices-status-input:focus-visible {
  outline: none;
}

.wow-devices-status-text {
  display: inline-block;
  line-height: 20px;
}

.wow-devices-status-controls {
  position: absolute;
  right: 3px;
  top: 3px;
}

.wow-devices-status-button {
  color: #6c757d;
  cursor: pointer;
  display: inline-block;
  font-size: 1.2em;
  line-height: 1em;
  user-select: none;
}

.wow-devices-copy {
  color: #6c757d;
  cursor: pointer;
  opacity: 0;
  user-select: none;
}

tr:hover .wow-devices-copy {
  opacity: 1;
}

@media (min-width: 576px) {
  .wow-devices-info-modal .modal-dialog,
  .wow-devices-history-modal .modal-dialog {
    max-width: 800px;
  }
}
